import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FiFile } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getAllProducts } from "../redux/helpers/controllerAPI";
import {
  addBulkDeviceAPI,
  addDeviceAPI,
  assignDeviceAPI,
  getDeviceAPI,
} from "../redux/helpers/deviceAPI";
import { getFirmwareListAPI } from "../redux/helpers/firmwareAPI";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";
import { getMemberAPI } from "../redux/helpers/teamAPI";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";
import SerialNumberGenerator from "./SerialNumberGenerator";

function AddUnitCard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { customer } = location.state || {};
  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    formItem: {
      // m:'4 0 4 0',
      margin: "15px 0px 5px 20px",
      maxWidth: 400,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
      cursor: isHovered ? "pointer" : "default",
      textDecoration: isHovered ? "underline" : "default",
    },
    cancelButton: {
      mr: 5,
      size: "sm",
      variant: "outline",
      justifyContent: "center",
    },
    submitButton: {
      colorScheme: "facebook",
      size: "sm",
      justifyContent: "end",
    },
  };

  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [device_type, setDeviceType] = useState(null);
  const [firmware_id, setFirmwareID] = useState(null);
  const [tested_by, setTestedBy] = useState(null);
  const [device_model, setModelSelected] = useState(null);
  const [customer_id, setAssignCustomer] = useState([]);
  const [producedDate, setProducedDate] = useState(null);
  const [currentModels, setCurrentModels] = useState([]);
  const [provisioningStatus, setProvisioningStatus] = useState();
  const [generateSerialNo, setGenerateSerialNo] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const types = useSelector((state) => state?.controllers?.types);
  const models = useSelector((state) => state?.controllers?.models);
  const customerOrgList = useSelector((state) => state.customer.organizations);
  const [isAssignToCustomer, setIsAssignToCustomer] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const teamMemberData = useSelector(
    (state) => state?.teamMember?.teamMemberData
  );
  const firmwareData = useSelector((state) => state?.firmware?.firmwareData);
  const [firmwareSelected, setFirmwareSelected] = useState([]);
  useEffect(() => {
    setSelectedFile(null);
    dispatch(getFirmwareListAPI());
    dispatch(getMemberAPI());
  }, []);

  const onTypeSelected = (typeID) => {
    setDeviceType(typeID);
    let currentModelsSelected = models?.filter(
      (model) => model?.device_type?.id == typeID
    );
    setCurrentModels(currentModelsSelected);

    let currentFirmwareSelected = firmwareData?.filter(
      (firmware) => firmware?.device_model?.id == currentModelsSelected[0]?.id
    );
    setFirmwareSelected(currentFirmwareSelected);
  };

  const onFirmwareSelected = (firmwareID) => {
    setFirmwareID(firmwareID);
  };

  const onTestedBySelected = (testedBy) => {
    setTestedBy(testedBy);
  };

  const onModelSelected = (model) => {
    setModelSelected(model);
    let currentFirmwareSelected = firmwareData?.filter(
      (firmware) => firmware?.device_model?.id == model
    );
    setFirmwareSelected(currentFirmwareSelected);
  };

  const onCustomerSelected = (customer) => {
    setAssignCustomer(customer);
  };

  const assignDeviceToCustomer = () => {
    setLoading(true);
    try {
      const deviceReq = dispatch(getDeviceAPI());
      deviceReq.then((result) => {
        const device = result.payload.data;
        const device_data_id = device?.filter((val) => {
          return val?.serial_number == serialNo;
        });
        const payload = {
          device_id: device_data_id[0]?.id,
          assigned_to_id: customer_id,
        };
        dispatch(assignDeviceAPI(payload));
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const nameRegex = /^[a-zA-Z0-9\s\-]+$/;
  const formValidationSchema = Yup.object().shape({
    device_type: Yup.string().required("Device type is required"),
    device_model: Yup.string().required("Device model is required"),
    // serial_no: Yup.string().required("Serial number is required"),
    provisioning_status: Yup.boolean(),
    firmware_id: Yup.string().required("Firmware is required"),
    date_produced: Yup.date().required("Date produced is required"),
    date_tested: Yup.date().required("Date tested is required"),
    tested_by_id: Yup.string().required("Tested by is required"),
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const CsvUpload = ({ register, children }) => {
    const changeHandler = (event) => {
      setSelectedFile(event?.target?.files[0]);
    };
    const inputRef = useRef(null);
    const { ref, ...rest } = register;

    const handleClick = () => inputRef.current?.click();

    return (
      <InputGroup onClick={handleClick} onChange={changeHandler}>
        <input
          type="file"
          accept=".csv,.xls,.xlsx"
          hidden
          {...rest}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
        />
        {children}
      </InputGroup>
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const file = data.csvFile[0];

    setLoading(true);
    try {
      const payload = {
        devices_data_file: file,
        create_new_if_not_present: false,
      };

      const addBulkDeviceReq = dispatch(addBulkDeviceAPI(payload));
      addBulkDeviceReq.then((data) => {
        if (data?.payload?.success) {
          dispatch(getDeviceAPI());
          dispatch(getDashboardDataAPI()), setLoading(false);
          setSuccessMessage("Products added successfully");
          setisSuccessModalOpen(true);
          navigate("/products");
        } else {
          setLoading(false);
          toast({
            title: "Error while adding Controller",
            description: data?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("errror");
    }
  };

  const SUPPORTED_FORMATS = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];

  const validateFiles = (value) => {
    if (value.length < 1) {
      return "File is required";
    }
    const file = value[0];
    if (file && SUPPORTED_FORMATS.includes(!file.type)) {
      return "Only CSV and EXCEL files are allowed";
    }
    return true;
  };

  const handleReset = () => {
    initialRef.current.reset();
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  const successToastMessage = (title, desc) => {
    toast({
      title: title,
      position: "top-right",
      description: desc,
      status: "success",
      duration: 6000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex direction={"row"} justifyContent="space-between" display={"none"}>
        <Text
          {...styles.bredcrumbsContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Add New Product
        </Text>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {selectedFile && (
              <Button
                disabled={selectedFile === null}
                colorScheme="facebook"
                size="sm"
                type="submit"
                mx={2}
              >
                Submit
              </Button>
            )}
            <FormControl isInvalid={!!errors.csvFile} isRequired>
              <CsvUpload
                register={register("csvFile", { validate: validateFiles })}
              >
                <Button
                  colorScheme={selectedFile === null ? "facebook" : null}
                  size="sm"
                  leftIcon={<Icon as={FiFile} />}
                >
                  {selectedFile === null
                    ? "Add Bulk Product"
                    : selectedFile?.name}
                </Button>
              </CsvUpload>

              <FormErrorMessage>
                {errors.csvFile && errors.csvFile.message}
              </FormErrorMessage>
            </FormControl>
          </div>
        </form>
      </Flex>
      <Text
        {...styles.bredcrumbsContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        Add New Production Record
      </Text>
      <Card size={{ base: "sm", md: "md" }}>
        <CardBody>
          <Formik
            initialValues={{
              product_qty: 1,
              revision_name: "",
              device_type: "",
              device_model: "",
              serial_no: "",
              provisioning_status: "",
              firmware_id: "",
              date_produced: "",
              date_tested: "",
              tested_by_id: "",
            }}
            validationSchema={formValidationSchema}
            onSubmit={async (values) => {
              setLoading(true);
              try {
                const data = {
                  product_qty: values?.product_qty,
                  revision_name: values?.revision_name,
                  type_id: device_type,
                  model_id: device_model,
                  provisioningStatus: values?.provisioning_status,
                  serial_number: values?.serial_no,
                  firmware_id: firmware_id,
                  date_produced: Math.floor(
                    new Date(values?.date_produced).getTime() / 1000
                  ),
                  date_tested: Math.floor(
                    new Date(values?.date_tested).getTime() / 1000
                  ),
                  tested_by_id: tested_by,
                };

                const addDeviceReq = dispatch(addDeviceAPI(data));
                addDeviceReq.then(async (data) => {
                  if (data?.payload?.success) {
                    if (isAssignToCustomer) {
                      assignDeviceToCustomer();
                    }
                    await dispatch(getDeviceAPI());
                    dispatch(getAllProducts()).then(() => {
                      successToastMessage(
                        "Success",
                        "Products added successfully"
                      );
                      setIsAssignToCustomer(false);
                      setGenerateSerialNo(null);
                      setLoading(false);
                      // setisSuccessModalOpen(true);
                      navigate(-1);
                    });
                  } else {
                    setLoading(false);
                    toast({
                      title: "Error while adding Controller",
                      description: data?.payload?.message,
                      status: "error",
                      duration: 6000,
                      isClosable: true,
                    });
                  }
                });
              } catch (err) {
                setLoading(false);
                console.log("errror");
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              resetForm,
              errors,
              touched,
              setFieldValue,
              values,
            }) => {
              return (
                <form onSubmit={handleSubmit} ref={initialRef}>
                  <Flex style={{ maxWidth: 400 }}>
                    {/* <FormControl
                      {...styles.formItem}
                      isInvalid={errors.product_qty && touched.product_qty}
                      typeof={"number"}
                    >
                      <Field name="product_qty" ref={initialRef}>
                        {({ field }) => (
                          <FormControl
                            isInvalid={
                              errors.product_qty && touched.product_qty
                            }
                          >
                            <FormLabel htmlFor="number">
                              Product Quantity
                            </FormLabel>
                            <NumberInput defaultValue={1} min={1} max={1000}>
                              <NumberInputField {...field} id="product_qty1"  />
                            </NumberInput>
                            <FormErrorMessage>
                              {errors.product_qty}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <FormErrorMessage>{errors.product_qty}</FormErrorMessage>
                    </FormControl> */}
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.product_qty && touched.product_qty}
                    >
                      <FormLabel>Product Quantity</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        placeholder="Product Quantity"
                        name="product_qty"
                        id="product_qty"
                        type={'number'}
                        defaultValue={1}
                        min={1}
                        max={1000}
                      />
                      <FormErrorMessage>{errors.product_qty}</FormErrorMessage>
                    </FormControl>
                  </Flex>

                  <Flex style={{ maxWidth: 400 }}>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.serial_no && touched.serial_no}
                    >
                      <FormLabel> Product Serial No </FormLabel>
                      <Checkbox
                        name="generateSerialNo"
                        colorScheme="green"
                        isChecked={generateSerialNo}
                        onChange={(e) => {
                          // let slNo = Date.now();

                          if (e.target.checked === false) {
                            setFieldValue("serial_no", null);
                          }
                          
                          console.log("e.target.checked", e.target.checked)
                          // setSerialNo(slNo);
                          setGenerateSerialNo(e.target.checked);
                        }}
                      >
                        Enter Custom Seed Serial Number
                      </Checkbox>
                      {/* <Field
                        as={Input}
                        {...styles.input}
                        style={{ marginTop: 10 }}
                        ref={initialRef}
                        placeholder="Serial No"
                        disabled={!generateSerialNo}
                        id="serial_no"
                        name="serial_no"
                      /> */}
                      {generateSerialNo == true ? (
                        <SerialNumberGenerator
                          onSave={(slNo) => {
                            setSerialNo(slNo);
                            setFieldValue("serial_no", slNo);
                          }}
                        />
                      ) : (
                        <></>
                      )}

                      <FormErrorMessage>{errors.serial_no}</FormErrorMessage>
                    </FormControl>
                  </Flex>

                  <Flex style={{ maxWidth: 400 }}>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_type && touched.device_type}
                    >
                      <FormLabel>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          Product
                          {/* <Button
                            size={"xs"}
                            type="primary"
                            width="8rem"
                            onClick={() => navigate("/add-type")}
                          >
                            <Text
                              textAlign={"end"}
                              fontSize={12}
                              color={"#0071FF"}
                            >
                              New Type +
                            </Text>
                          </Button> */}
                        </Box>
                      </FormLabel>
                      <Select
                        {...styles.input}
                        id="device_type"
                        name="device_type"
                        placeholder="Select Product"
                        onChange={(event) => {
                          setFieldValue("device_type", event.target.value);
                          onTypeSelected(event.target.value);
                        }}
                      >
                        {types
                          ?.slice()
                          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                          ?.map((val, index) => {
                            return (
                              <option key={index} value={val.id}>
                                {val?.name}
                              </option>
                            );
                          })}
                      </Select>
                      <FormErrorMessage>{errors.device_type}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex direction={"row"} style={{ maxWidth: 400 }}>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_model && touched.device_model}
                    >
                      <FormLabel>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          Model
                          {/* <Button
                            size={"xs"}
                            type="primary"
                            width="8rem"
                            onClick={() => {
                              navigate("/add-model");
                            }}
                          >
                            <Text
                              textAlign={"end"}
                              fontSize={12}
                              color={"#0071FF"}
                            >
                              New Model +
                            </Text>
                          </Button> */}
                        </Box>
                      </FormLabel>
                      <Select
                        {...styles.input}
                        id="device_model"
                        name="device_model"
                        placeholder="Select Product"
                        onChange={(event) => {
                          setFieldValue("device_model", event.target.value);
                          onModelSelected(event.target.value);
                        }}
                      >
                        {currentModels
                          ?.slice()
                          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                          ?.map((val, index) => {
                            return (
                              <option key={index} value={val.id}>
                                {val?.name}
                              </option>
                            );
                          })}
                      </Select>
                      <FormErrorMessage>{errors.device_model}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex direction={"row"} style={{ maxWidth: 400 }}>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_name && touched.device_name}
                    >
                      <FormLabel>Revision Name</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        placeholder="Revision Name"
                        name="revision_name"
                        id="revision_name"
                      />
                      <FormErrorMessage>{errors.device_name}</FormErrorMessage>
                    </FormControl>
                  </Flex>

                  <Flex style={{ maxWidth: 400 }}>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.firmware_id && touched.firmware_id}
                    >
                      <FormLabel>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          Firmware
                          {/* <Button
                            size={"xs"}
                            type="primary"
                            width="8rem"
                            onClick={() => {
                              navigate("/firmware");
                            }}
                          >
                            <Text
                              textAlign={"end"}
                              fontSize={12}
                              color={"#0071FF"}
                            >
                              New Firmware +
                            </Text>
                          </Button> */}
                        </Box>
                      </FormLabel>
                      <Select
                        {...styles.input}
                        placeholder="Select Model"
                        id="firmware_id"
                        name="firmware_id"
                        onChange={(event) => {
                          setFieldValue("firmware_id", event.target.value);
                          onFirmwareSelected(event.target.value);
                        }}
                      >
                        {firmwareSelected
                          ?.slice()
                          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                          ?.map((val, index) => {
                            return (
                              <option key={index} value={val.id}>
                                {val?.name}
                              </option>
                            );
                          })}
                      </Select>
                      <FormErrorMessage>{errors.firmware_id}</FormErrorMessage>
                    </FormControl>
                  </Flex>

                  <Flex direction={"row"}>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.date_produced && touched.date_produced}
                      style={{ maxWidth: 400 }}
                    >
                      <FormLabel>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          Date Produced
                        </Box>
                      </FormLabel>
                      <Field
                        {...styles.input}
                        as={Input}
                        placeholder="Select Date"
                        id="date_produced"
                        name="date_produced"
                        max={new Date().toISOString().split("T")[0]}
                        type="date"
                        onChange={(event) => {
                          setProducedDate(event.target.value);
                          setFieldValue("date_produced", event.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        {errors.date_produced}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.date_tested && touched.date_tested}
                      style={{ maxWidth: 400 }}
                    >
                      <FormLabel>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          Date Tested
                        </Box>
                      </FormLabel>
                      <Field
                        {...styles.input}
                        as={Input}
                        placeholder="Select Date"
                        min={new Date(producedDate).toISOString().split("T")[0]}
                        max={new Date().toISOString().split("T")[0]}
                        id="date_tested"
                        name="date_tested"
                        type="date"
                      />
                      <FormErrorMessage>{errors.date_tested}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex direction={"row"} style={{ maxWidth: 400 }}>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.tested_by_id && touched.tested_by_id}
                      style={{ maxWidth: 400 }}
                    >
                      <FormLabel>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          Tested By
                        </Box>
                      </FormLabel>
                      <Select
                        {...styles.input}
                        placeholder="Select Tested By"
                        id="tested_by_id"
                        name="tested_by_id"
                        onChange={(event) => {
                          setFieldValue("tested_by_id", event.target.value);
                          onTestedBySelected(event.target.value);
                        }}
                      >
                        {teamMemberData
                          ?.slice()
                          ?.sort((a, b) =>
                            a?.first_name?.localeCompare(b?.first_name)
                          )
                          ?.map((val, index) => {
                            return (
                              <option key={index} value={val.id}>
                                {val.first_name} {val.last_name}
                              </option>
                            );
                          })}
                      </Select>
                      <FormErrorMessage>{errors.tested_by_id}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex style={{ maxWidth: 400 }}>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={
                        errors.assign_Customer && touched.assign_Customer
                      }
                    >
                      <Checkbox
                        name="assign_Customer"
                        colorScheme="green"
                        isChecked={isAssignToCustomer}
                        onChange={(e) =>
                          setIsAssignToCustomer(e.target.checked)
                        }
                      >
                        Assign to Customers?
                      </Checkbox>
                      {isAssignToCustomer && (
                        <Select
                          marginTop={"10px"}
                          {...styles.input}
                          placeholder="Select Customer"
                          id="assign_Customer"
                          name="assign_Customer"
                          onChange={(event) =>
                            onCustomerSelected(event.target.value)
                          }
                        >
                          {customerOrgList?.map((val, index) => {
                            return (
                              <option key={index} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </Select>
                      )}
                      <FormErrorMessage>
                        {errors.assign_Customer}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={
                        errors.provisioning_status &&
                        touched.provisioning_status
                      }
                    >
                      <FormLabel>Telemetry Status </FormLabel>
                      <Checkbox
                        name="provisioning_status"
                        id="provisioning_status"
                        colorScheme="green"
                        isChecked={provisioningStatus}
                        onChange={(e) =>
                          setProvisioningStatus(e.target.checked)
                        }
                      >
                        Enabled
                      </Checkbox>
                      <FormErrorMessage>
                        {errors.provisioning_status}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex mt={4} justifyContent={"spa"}>
                    <Button
                      // background="red"
                      colorScheme={"red"}
                      variant={"outline"}
                      width="8rem"
                      size="sm"
                      mr={5}
                      onClick={handleBackClick}
                      // color="white"
                    >
                      Cancel
                    </Button>
                    <Button
                      size={"sm"}
                      width="8rem"
                      onClick={() => {
                        resetForm();
                        handleReset();
                        setGenerateSerialNo("");
                      }}
                      {...styles.cancelButton}
                    >
                      Clear Form
                    </Button>
                    <Button
                      type="submit"
                      width="8rem"
                      onClick={handleSubmit}
                      {...styles.submitButton}
                    >
                      Create Product
                    </Button>
                  </Flex>
                </form>
              );
            }}
          </Formik>
          {/* </Flex> */}
          {isSuccessModalOpen && (
            <SuccessMessageModal
              isOpen={isSuccessModalOpen}
              message={`Device ${successMessage} successfully!`}
              onClose={() => {
                setisSuccessModalOpen(false);
                navigate("/products");
              }}
            />
          )}
          {/* <Box>
            <Button> Assign Device </Button>
          </Box> */}
          {/* </Flex> */}
        </CardBody>
      </Card>
    </Box>
  );
}

export default AddUnitCard;
